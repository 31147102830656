import React from 'react'
import {Link} from 'gatsby'

import Content from 'organisms/Content'
import Main from 'organisms/Main'
import SEO from 'molecules/Seo'
import PageTitle from 'atoms/PageTitle'

import {wrapper, title} from './404.module.scss'

/**
 * Not Found Page
 * @return {Element} The Not Found Page.
 */
export default function NotFoundPage() {
  return (
    <Content>
      <SEO title="Page not found" />
      <Main>
        <div className={wrapper}>
          <PageTitle className={title} title="Not found, error 404" />
          <p>
            The page you are looking for no longer exists. Perhaps you can
            return back to the <Link to="/">homepage</Link> and see if you can
            find what you are looking for. Or, you can try finding it by using
            the <Link to="/search">search page</Link>.
          </p>
        </div>
      </Main>
    </Content>
  )
}
